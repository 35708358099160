import { Box3, Camera, Frustum, Matrix4, OrthographicCamera, PerspectiveCamera, Vector2, Vector3 } from "three";
import { AbstractVisibleNodesStrategy, INFINITE_SIZE_ON_SCREEN } from "../Lod/AbstractVisibleNodesStrategy";
import { LodTree, LodTreeNode, NodeState } from "../Lod/LodTree";
import { OptimizationHint, VisibleNodesStrategy, WeightedNode } from "../Lod/VisibleNodeStrategy";
import { LeanBinaryHeap } from "../Utils/LeanBinaryHeap";
import { DEG_TO_RAD } from "../Utils/Math";

const RADIUS_MULTIPLIER = Math.sqrt(3) / 2;

const DEFAULT_MIN_PROJECTED_SIZE = 25;

/** Function that takes the projection data of an object and compute its size in pixels on screen */
type ProjectionFunction = (distance: number, radius: number) => number;

/** Function that checks whether the given bounding box intersects one or more frustums */
type FrustumCheckFnc = (bbox: Box3) => boolean;

/**
 * A visible nodes strategy that reproduces what the Potree renderer does.
 *
 * WARNING: this strategy does not use the 'targetPixelsPerPoint' property. Instead,
 * set a convenient 'minNodeProjectedSize' property.
 */
export class PotreeVisibleNodesStrategy extends AbstractVisibleNodesStrategy implements VisibleNodesStrategy {
	// These private class members do not start with #hash, because at least in local environments #hash privates are slower
	private cameraFrustum = new Frustum();
	private T = new Matrix4();
	private PT = new Matrix4();
	private bbCenter = new Vector3();
	private pMinNodeProjectedSize = DEFAULT_MIN_PROJECTED_SIZE;
	private meters2pix = 1.0;
	private visibleNodes = new Array<WeightedNode>();
	private numVisiblePoints = 0;
	private nodesNotInUse = new Array<number>();
	private auxFrustum = new Frustum();
	private frustumChecker: FrustumCheckFnc;

	private priorityQueue = new LeanBinaryHeap<WeightedNode>((x: WeightedNode) => -x.weight);

	constructor() {
		super();
		this.maxPointsInGpu = 5 * 1000 * 1000;
		this.projSizePerspective = this.projSizePerspective.bind(this);
		this.projSizeOrthographic = this.projSizeOrthographic.bind(this);
		this.cameraFrustumCheck = this.cameraFrustumCheck.bind(this);
		this.cameraAndAuxFrustumCheck = this.cameraAndAuxFrustumCheck.bind(this);
		this.frustumChecker = this.cameraFrustumCheck;
	}

	/**
	 *
	 * @param distance node->camera distance
	 * @param radius node 3d radius
	 * @returns the projected size of the node, in screen pixels.
	 */
	private projSizePerspective(distance: number, radius: number): number {
		return (this.meters2pix * radius) / distance;
	}

	/**
	 *
	 * @param _distance node->camera distance
	 * @param radius node 3d radius
	 * @returns the projected size of the node, in screen pixels.
	 */
	private projSizeOrthographic(_distance: number, radius: number): number {
		return this.meters2pix * radius;
	}

	private cameraFrustumCheck(bbox: Box3): boolean {
		return this.cameraFrustum.intersectsBox(bbox);
	}

	private cameraAndAuxFrustumCheck(bbox: Box3): boolean {
		return this.cameraFrustum.intersectsBox(bbox) && this.auxFrustum.intersectsBox(bbox);
	}

	/**
	 * @param node the node
	 * @param projSizeFunc projected size computation function (persp or ortho)
	 */
	private pushNodeToQueueIfVisible(node: LodTreeNode, projSizeFunc: ProjectionFunction): void {
		const bbox = node.boundingBox;
		if (!this.frustumChecker(bbox)) {
			return;
		}

		bbox.getCenter(this.bbCenter);
		this.bbCenter.applyMatrix4(this.T);
		const distance = this.bbCenter.length();
		let projectedSize = INFINITE_SIZE_ON_SCREEN;
		// The if below is placed to avoid a division by zero
		if (distance > 0.001) {
			const radius = (bbox.max.x - bbox.min.x) * this.pCloudScale * RADIUS_MULTIPLIER;
			projectedSize = projSizeFunc(distance, radius);
		}
		if (projectedSize < this.pMinNodeProjectedSize) {
			return;
		}
		this.priorityQueue.push({ id: node.id, weight: projectedSize });
	}

	/**
	 * Visits the octree starting from the elements in the priority queue.
	 * New children to visit are weighted and pushed to the priority queue.
	 * Visit ends when the priority queue is empty.
	 *
	 * @param tree The octree
	 * @param projSizeFunc perspective or ortho projection function
	 */
	private visitQueue(tree: LodTree, projSizeFunc: ProjectionFunction): void {
		while (this.priorityQueue.length > 0) {
			const element = this.priorityQueue.pop();
			const node = tree.getNode(element.id);

			if (this.numVisiblePoints + node.numPoints > this.pMaxPointsInGPU) {
				break;
			}

			this.numVisiblePoints += node.numPoints;
			this.visibleNodes.push(element);
			// Put in the viewer only nodes whose parent already has points
			if (node.state !== NodeState.InUse) {
				this.nodesNotInUse.push(node.id);
				continue;
			}
			for (const child of node.children) {
				this.pushNodeToQueueIfVisible(child, projSizeFunc);
			}
		}
	}

	/**
	 *
	 * @param tree tree
	 * @param cloudWorldMatrix cloud pose matrix
	 * @param camera current camera
	 * @param screenSize current screen size
	 * @param frustum optional aux frustum to give visibility only to nodes within a given clipping box
	 * @returns list of visible weighted node
	 */
	private recompute(
		tree: LodTree,
		cloudWorldMatrix: Matrix4,
		camera: Camera,
		screenSize: Vector2,
		frustum?: Frustum,
	): WeightedNode[] {
		// T transforms from the LOD cloud local coordinates to the camera local coordinates
		this.T.multiplyMatrices(camera.matrixWorldInverse, cloudWorldMatrix);

		this.PT.multiplyMatrices(camera.projectionMatrix, this.T);
		this.cameraFrustum.setFromProjectionMatrix(this.PT);

		this.extractCloudScale(cloudWorldMatrix);

		const projSizeFunc: ProjectionFunction =
			camera instanceof PerspectiveCamera ? this.projSizePerspective : this.projSizeOrthographic;

		if (frustum) {
			this.auxFrustum.copy(frustum);
			this.frustumChecker = this.cameraAndAuxFrustumCheck;
		} else {
			this.frustumChecker = this.cameraFrustumCheck;
		}

		this.visibleNodes.length = 0;

		if (camera instanceof PerspectiveCamera) {
			this.meters2pix = (screenSize.y * 0.5) / Math.tan((camera.getEffectiveFOV() * DEG_TO_RAD) / 2);
		} else if (camera instanceof OrthographicCamera) {
			this.meters2pix = screenSize.y / (camera.top - camera.bottom);
		}
		this.numVisiblePoints = 0;

		/** The queue should be empty, but we clear it anyway just in case */
		this.priorityQueue.clear();

		this.priorityQueue.push({ id: 0, weight: Number.MAX_VALUE });

		this.nodesNotInUse.length = 0;

		this.visitQueue(tree, projSizeFunc);

		return this.visibleNodes;
	}

	/**
	 *
	 * @param tree tree
	 * @param camera current camera
	 * @returns list of visible weighted node
	 */
	computeIncremental(tree: LodTree, camera: Camera): WeightedNode[] {
		const projSizeFunc: ProjectionFunction =
			camera instanceof PerspectiveCamera ? this.projSizePerspective : this.projSizeOrthographic;

		/** The queue should be empty, but we clear it anyway just in case */
		this.priorityQueue.clear();

		// Check all nodes that were waiting for download. If a node arrived,
		// put its children in the priority queue. If otherwise the node is still
		// waiting, push it in 'newNodesWaiting'
		const newNodesNotInUse = new Array<number>();
		for (const nodeToWait of this.nodesNotInUse) {
			const node = tree.getNode(nodeToWait);
			if (node.state === NodeState.InUse) {
				for (const child of node.children) {
					this.pushNodeToQueueIfVisible(child, projSizeFunc);
				}
			} else {
				newNodesNotInUse.push(nodeToWait);
			}
		}

		// Update the list of nodes waiting for download, more nodes will be added
		// inside 'visitQueue'
		this.nodesNotInUse = newNodesNotInUse;

		this.visitQueue(tree, projSizeFunc);

		return this.visibleNodes;
	}

	/** @inheritdoc */
	compute(
		tree: LodTree,
		cloudWorldMatrix: Matrix4,
		camera: Camera,
		screenSize: Vector2,
		frustum?: Frustum,
	): WeightedNode[] {
		switch (this.optimizationHint) {
			case OptimizationHint.cameraChanging:
				return this.recompute(tree, cloudWorldMatrix, camera, screenSize, frustum);
			case OptimizationHint.cameraSteady:
				return this.computeIncremental(tree, camera);
			case OptimizationHint.cameraChangingOnce: {
				const ret = this.recompute(tree, cloudWorldMatrix, camera, screenSize, frustum);
				this.optimizationHint = OptimizationHint.cameraSteady;
				return ret;
			}
		}
	}

	/** @returns the minimum projected size in pixels a node must have to be visible */
	get minNodeProjectedSize(): number {
		return this.pMinNodeProjectedSize;
	}

	/** Sets the minimum projected size in pixels a node must have to be visible */
	set minNodeProjectedSize(m: number) {
		this.pMinNodeProjectedSize = m;
	}

	/** @inheritdoc */
	clone(): PotreeVisibleNodesStrategy {
		const clone = new PotreeVisibleNodesStrategy();
		clone.copy(this);
		clone.minNodeProjectedSize = this.minNodeProjectedSize;
		return clone;
	}
}
